import React from "react";

export default function ProductCard({ product }) {
  const formatPrice = (amount) => {
    const parsedAmount = parseFloat(amount);
    return new Intl.NumberFormat("sr-RS", { minimumFractionDigits: 2 })
      .format(parsedAmount)
      .replace(/\s/g, "");
  };

  const getCheapestVariant = () => {
    const variants = product.variants;

    if (!variants) return { price: 0, title: "N/A" };

    // Find the index of the variant with the cheapest price
    const cheapestPriceIndex = variants.reduce(
      (cheapestIndex, variant, index) => {
        const currentPrice = variant.prices[0].amount;
        return currentPrice < variants[cheapestIndex].prices[0].amount
          ? index
          : cheapestIndex;
      },
      0
    ); // Initial cheapest index is 0 (assuming at least one variant exists)

    // Extract the cheapest variant and its title
    const cheapestVariant = variants[cheapestPriceIndex];
    const cheapestVariantTitle = cheapestVariant.title; // Assuming the variant has a `title` property

    return {
      price: cheapestVariant.prices[0].amount,
      title: cheapestVariantTitle,
    };
  };
  const { price, title } = getCheapestVariant();

  const handleItemSelect = () => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_id: "related_products",
        item_list_name: "Related products",
        items: [
          {
            item_id: product.id,
            item_name: product.title,
            affiliation: "DURO",
            index: 0,
            item_brand: product?.collection?.title,
            item_category: product?.categories?.[0]?.name,
            item_list_id: "related_products",
            item_list_name: "Related products",
            item_variant: title,
            location_id: "",
            price: price,
            quantity: product.variants?.[0]?.inventory_quantity,
          },
        ],
      },
    });
  };
  return (
    <div className="product-card">
      <a
        onClick={handleItemSelect}
        href={`https://shop.parfimerija-duro.rs/rs/products/${product?.handle}`}
        className="card-link"
      >
        <div className="image-wrapper">
          <img
            src={product.thumbnail}
            className="product-image"
            alt={product.title}
          />
          <div className="overlay">
            <i className="fa fa fa-shopping-cart cart-icon"></i>
          </div>
        </div>
        <div className="info-section">
          <p className="collection-text"></p>
          <p className="product-title">{product.title}</p>
          <p className="product-collection">
            {product?.collection?.title} | {title}
          </p>
          <p className="product-price">
            {formatPrice(price)}{" "}
            {product?.variants?.[0]?.prices[0]?.currency_code ?? "din."}
          </p>
        </div>
      </a>
    </div>
  );
}
