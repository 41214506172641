import React, { useEffect } from "react";
import $ from "jquery";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Newsletter from "../components/Newsletter";
import Breadcrumbs from "../components/Breadcrumbs";

export default function Gallery({ setHeader }) {
  const { t } = useTranslation();
  const imageSrcList = [
    "/assets/images/gallery/1.jpg",
    "/assets/images/gallery/2.jpg",
    "/assets/images/gallery/3.jpg",
    "/assets/images/gallery/4.jpg",
    "/assets/images/gallery/5.jpg",
    "/assets/images/gallery/6.jpg",
    "/assets/images/gallery/7.jpg",
    "/assets/images/gallery/8.jpg",
    "/assets/images/gallery/9.jpg",
    "/assets/images/gallery/10.jpg",
    "/assets/images/gallery/11.jpg",
    "/assets/images/gallery/12.jpg",
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setHeader(true);
      } else {
        setHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [setHeader]);

  useEffect(() => {
    $(".image-popup").magnificPopup({
      type: "image",
      callbacks: {
        beforeOpen: function () {
          this.st.image.markup = this.st.image.markup.replace(
            "mfp-figure",
            "mfp-figure animated zoomInDown"
          );
        },
      },
      gallery: {
        enabled: true,
      },
    });
  }, []);

  return (
    <>
      <Helmet>
        {/* <!-- meta tag --> */}
        <meta charset="utf-8" />
        <title>
          {t("header.gallery.pageTitle")} - DURŌ Niche perfumery - Niche
          parfimerija
        </title>

        {/* <!-- Meta Tags--> */}
        <meta
          name="description"
          content={t("header.gallery.metaDescription")}
        />
        <meta
          name="keywords"
          content="durō, duro, niche perfumery, niche parfimerija, beograd, parfimerija, galerija"
        />
        <meta name="author" content="intellect.se" />

        {/* <!-- responsive tag --> */}
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* <!-- Facebook Metadata --> */}
        <meta property="og:image:height" content="1200" />
        <meta property="og:image:width" content="630" />
        <meta property="og:title" content="DURŌ | Niche perfumery" />
        <meta
          property="og:description"
          content="🖤 DURŌ Black Friday Pitanje nije šta vidite, već šta osećate. Luksuz u najfinijem obliku, sakriven u polutami. DURŌ zlatni dodir ove godine otkriva mirisne tajne zavodljivosti na potpuno nov način."
        />
        <meta property="og:url" content="https://www.parfimerija-duro.rs/black-friday-shopping-festival" />
        <meta
          property="og:image"
          content="https://www.parfimerija-duro.rs/assets/images/favicon/og-image-black-friday.png"
        />
      </Helmet>
      <Loader />
      {/* <!-- Main content Start --> */}
      <div className="main-content blue-bg">
        {/* <!-- Breadcrumbs Start --> */}
        <div className="rs-breadcrumbs img6">
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                {t("gallery")}
                {/*<span className="watermark">{gallery}</span>*/}
              </h1>
            </div>
          </div>
        </div>
        <Breadcrumbs />
        {/* <!-- Breadcrumbs End --> */}

        {/* <!-- Gallery Start --> */}

        <div className="container">
          <div className="row y-middle">
            <div className="rs-gallery style1 mt-60 mb-30">
              <div className="row no-gutters">
                {imageSrcList.map((img) => (
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="gallery-item margin">
                      <div className="gallery-icon">
                        <a className="image-popup" href={img}>
                          <img src={img} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>  
        {/* <!-- Gallery End --> */}

        {/* <!-- Newsletter section start --> */}
        <div className="rs-newsletter blue-bg style1 pt-90 md-pt-60 mb--65">
          <Newsletter />
        </div>
        {/* <!-- Newsletter section end --> */}
      </div>
      {/* <!-- Main content End --> */}
    </>
  );
}
