import React from 'react';
import { useTranslation } from 'react-i18next';

const HeroBanner = ({ bannerData }) => {
  const { t } = useTranslation();

  return (
    <a
      href={bannerData.link}
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        minHeight: '100vh',
        overflow: 'hidden',
        display: 'block'
      }}
    >
      <div
        className={`rs-banner ${bannerData.className} banner-home-style2`} 
      >
        {/* 
        <div className="container">
          <div className="content-wrap">
            <h2 className="title wow fadeInDown">{t(bannerData.titleKey)}</h2>
            <h2 className="small-title wow fadeInLeft">{t(bannerData.smallTitleKey)}</h2>
            <span className="sub-title wow fadeInLeft new">
              {t(bannerData.subTitleKey)}
            </span>
            <div className="btn-part wow fadeinup new">
              <a className="readon btn-text ticket" href={bannerData.buttonLink}>
                <span>{t(bannerData.buttonTextKey)}</span>
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </a>
  );
};

export default HeroBanner;