import { medusaClient } from "./config";

export const fetchAllProducts = async (sort, limit, offset) => {
  const params = {
    limit:99999,
    offset:0
  };
  if (sort === "latest") params["order"] = "-created_at";
  else if (sort?.includes("price")) params["description"] = sort;
  // else if (sort?.includes("popular")) {
  //   params["expand"] = "tags,variants,variants.prices,collection,categories";
  // }

  params["expand"] = "tags,variants,variants.prices,collection,categories";
  const { products,count } = await medusaClient.products.list(params);
  if (!products.length) {
    throw new Error("Network response was not ok");
  }

  if (sort?.includes("popular")) {
    try {
      const sortedProducts = sortByFeaturedTag(products);
      const sorted = sortedProducts.slice(offset, offset + limit);
      return [sorted,count];
    } catch (err) {
      return [products.slice(offset, offset + limit),count];
    }

    // return sortedProducts.slice(offset, offset + limit);
  } else return [products.slice(offset, offset + limit),count];
};

export const fetchProducts = async (slug, sort,limit,offset) => {
  const { collections } = await medusaClient.collections.list({
    handle: [slug],
  });
  const params = {
    limit: 99999,
    offset: 0,
    collection_id: [collections?.[0]?.id],
  };
  if (sort === "latest") params["order"] = "-created_at";
  else if (sort?.includes("price")) params["description"] = sort;
  // else if (sort?.includes("popular")) {
  //   params["expand"] = "tags,variants,variants.prices,collection,categories";
  // }
  params["expand"] = "tags,variants,variants.prices,collection,categories";
  const { products,count } = await medusaClient.products.list(params);
  if (!products.length) {
    throw new Error("Network response was not ok");
  }

  if (sort?.includes("popular")) {
    try {
      const sortedProducts = sortByFeaturedTag(products);
      const sorted = sortedProducts.slice(offset, offset + limit);
      return [sorted,count];
    } catch (err) {
      return [products.slice(offset, offset + limit),count];
    }

    // return sortedProducts.slice(offset, offset + limit);
  } else return [products.slice(offset, offset + limit),count];
};

function sortByFeaturedTag(products) {
  return products.sort((a, b) => {
    const hasFeaturedA = a.tags.some((t) => t.value === "featured");
    const hasFeaturedB = b.tags.some((t) => t.value === "featured");

    // Prioritize products with the "featured" tag
    if (hasFeaturedA && !hasFeaturedB) {
      return -1;
    } else if (!hasFeaturedA && hasFeaturedB) {
      return 1;
    } else {
      // If both have or neither have the "featured" tag, maintain original order
      return 0;
    }
  });
}
export const fetchCollections = async () => {
  const { collections } = await medusaClient.collections.list({ limit: 99 });
  if (!collections.length) {
    throw new Error("Network response was not ok");
  }
  return collections;
};
