// SearchComponent.js
import React, { useState, useEffect } from "react";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";
import searchClient from "../lib/algoliaClient";
const Hit = ({ hit }) => {
  const getCheapest = (hit) => {
    const cheapestVariant = hit.variants.reduce((prev, curr) => {
      return prev.calculated_price < curr.calculated_price ? prev : curr;
    });
    return cheapestVariant.prices[0].amount;
  };
  return (
    <a
      href={`https://shop.parfimerija-duro.rs/rs/products/${hit.handle}`}
      className="mb-2 w-100 hit-product"
      style={{
        position: "relative",
        color: "#949494",
        fontSize: "1.15rem",
        display: "flex",
        cursor: "pointer",
      }}
    >
      <div
        className="flex-row justify-content-between align-items-center"
        style={{ display: "flex", height: "100px" }}
      >
        <img
          src={hit.thumbnail}
          alt={hit.title}
          className="me-2 object-fit-cover"
          style={{ height: "100px", width: "100px" }}
        />
        {/* <div className="flex-column gap-2" style={{display: 'flex'}}> */}
        <span>{hit.title}</span>
        {/* <span>From {getCheapest(hit)} din.</span> */}
        {/* </div> */}
      </div>
    </a>
  );
};

const SearchComponent = ({isVisible,setIsVisible}) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsVisible((prev) => !prev);
      }
      if (event.key === "Escape") {
        setIsVisible(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div className="position-fixed top-0 start-0 w-100 h-100 bg-opacity-25 d-flex justify-content-center">
          <div
            className="p-4 shadow-lg w-100 h-100"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
          >
            <InstantSearch searchClient={searchClient} indexName="production">
              <div className="flex-row justify-content-between w-100 h-20 align-items-center" style={{display: 'flex'}}>
                <SearchBox />
                <div
                  onClick={() => setIsVisible(false)}
                  style={{ height: "40px", width: "40px", cursor: "pointer" }}
                >
                  <svg
                    fill="#FFFFFF"
                    version="1.1"
                    id="Capa_1"
                    viewBox="0 0 490 490"
                  >
                    <polygon
                      points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 
	489.292,457.678 277.331,245.004 489.292,32.337 "
                    />
                  </svg>
                </div>
              </div>
              <div
                className="mt-3"
                style={{ overflowY: "scroll", height: "80vh" }}
              >
                <Hits hitComponent={Hit} />
              </div>
            </InstantSearch>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchComponent;
