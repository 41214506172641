import React from "react";
import { useLocation, Link } from "react-router-dom";

const BreadcrumbsTwo = ({ bg, crumbs }) => {
  const location = useLocation();

  return (
    <div className={`${bg === "light" ? "gray-bg4" : "blue-bg"} py-4`}>
      {crumbs.length > 0 && (
        <nav className="container">
          <ul
            className={`${
              bg === "light" ? "breadcrumbs-list-light" : "breadcrumbs-list"
            } breadcrumbs-flex gap-3`}
          >
            <li>
              <Link to={`/${location.pathname.split("/")[1]}`}>
                <i className="fa-solid fa-house"></i>
              </Link>
            </li>
            {crumbs.map((crumb, index) => {
              const isLast = index === crumbs.length - 1;

              return (
                <React.Fragment key={index}>
                  <li>
                    <i className="fa-solid fa-chevron-right breadcrumbs-separator"></i>
                  </li>
                  <li>
                    {isLast ? (
                      <span className="capitalize breadcrumbs-active">
                        {crumb.title}
                      </span>
                    ) : (
                      <Link to={crumb.link} className="capitalize">
                        {crumb.title}
                      </Link>
                    )}
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default BreadcrumbsTwo;
