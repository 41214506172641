import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { Helmet } from "react-helmet";
import Newsletter from "../components/Newsletter";
import brandsData from "../data/brands.json";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../components/Breadcrumbs";
import ProductCard from "../components/ProductCard";
import { useQuery } from "@tanstack/react-query";
import { fetchProducts } from "../lib/medusa";

export default function Products({ setHeader }) {
  const { slug } = useParams();

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  let sort = searchParams.get("sort");


  const [limit, setLimit] = useState(12);
  const [offset, setOffset] = useState(0);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);

  const {isLoading, refetch } = useQuery({
    queryKey: ["products",slug,sort,limit,offset],
    queryFn: () => fetchProducts(slug, sort, limit, offset),
    onSuccess: (data) => {
      if (data?.length) {
        setProducts(data[0]);
        setCount(data[1]);
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "view_item_list",
          ecommerce: {
            item_list_id: "related_products",
            item_list_name: "Related products",
            items: data[0].map((product, index) => ({
              item_id: product.id,
              item_name: product.title,
              affiliation: "DURO",
              index: index,
              item_brand: product?.collection?.title,
              item_category: product?.categories?.[0]?.name,
              item_list_id: "related_products",
              item_list_name: "Related products",
              item_variant: product?.variants?.[0]?.title,
              location_id: "",
              price: product.variants?.[0]?.prices?.[0]?.amount,
              quantity: product.variants?.[0]?.inventory_quantity,
            })),
          },
        });
      }
    }
  });

  useEffect(() => {
    refetch();
  }, [offset, sort, limit, refetch]);

  const totalPages = Math.ceil(count / limit);

  const handleNextPage = () => {
    if (offset + limit < count) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  const handlePreviousPage = () => {
    if (offset > 0) {
      setOffset((prevOffset) => prevOffset - limit);
    }
  };

  const handleFirstPage = () => {
    setOffset(0);
  };

  const handleLastPage = () => {
    setOffset(limit * (totalPages - 1));
  };
  const [currentBrand, setCurrentBrand] = useState(brandsData[slug]);

  useEffect(() => {
    const handleScroll = () => {
      const bottomOffset = 400;
      const reachedNearBottom =
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - bottomOffset;

      setIsOverlayVisible(!reachedNearBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    sort = searchParams.get("sort");
    setCurrentBrand(brandsData[slug]);
    refetch();
  }, [slug]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setHeader(true);
      } else {
        setHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [setHeader]);

  const sortBy = (input) => {
    // Set the sort value in your state or variable (replace with your implementation)
    sort = input;

    // Create a URLSearchParams object
    const params = new URLSearchParams(window.location.search);

    // Check if the 'sort' parameter already exists
    if (params.has("sort")) {
      // If clicked input matches existing sort, remove the parameter
      if (params.get("sort") === input) {
        params.delete("sort");
      } else {
        // If clicked input is different, replace with new 'sort' value
        setSearchParams(`sort=${input}`);
        params.set("sort", input);
      }
    } else {
      // If no 'sort' parameter exists, append the new input
      setSearchParams(`sort=${input}`);
      params.append("sort", input);
    }

    // Create the new URL with the updated parameters (if necessary)
    const newUrl = params.toString()
      ? `${window.location.pathname}?${params.toString()}`
      : window.location.href;

    // Update the history state only if the URL changed
    if (newUrl !== window.location.href) {
      window.history.pushState({}, "", newUrl);
    }

    // Refetch data (assuming you have a refetch function)
    refetch();
  };
  return (
    <>
      <Helmet>
        {/* <!-- meta tag --> */}
        <meta charset="utf-8" />
        <title>
          {t(currentBrand.title)} - DURŌ Niche perfumery - Niche parfimerija
        </title>

        {/* <!-- Meta Tags--> */}
        <meta name="description" content={t("header.brands.metaDescription")} />
        <meta
          name="keywords"
          content="durō, duro, niche perfumery, niche parfimerija, beograd, parfimerija, galerija"
        />
        <meta name="author" content="intellect.se" />

        {/* <!-- responsive tag --> */}
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* <!-- Facebook Metadata --> */}
        <meta property="og:image:height" content="1200" />
        <meta property="og:image:width" content="630" />
        <meta property="og:title" content="DURŌ | Niche perfumery" />
        <meta
          property="og:description"
          content="🖤 DURŌ Black Friday Pitanje nije šta vidite, već šta osećate. Luksuz u najfinijem obliku, sakriven u polutami. DURŌ zlatni dodir ove godine otkriva mirisne tajne zavodljivosti na potpuno nov način."
        />
        <meta property="og:url" content="https://www.parfimerija-duro.rs/black-friday-shopping-festival" />
        <meta
          property="og:image"
          content="https://www.parfimerija-duro.rs/assets/images/favicon/og-image-black-friday.png"
        />
      </Helmet>
      <Loader />
      {/* // <!-- Main content Start --> */}
      <div className="main-content">
        {/* <!-- Breadcrumbs Start --> */}
        <div
          className="rs-breadcrumbs electimuss-3"
          style={{
            backgroundImage: `url(${
              currentBrand.heroImg === ""
                ? "/assets/images/breadcrumbs/inr-2.jpg"
                : currentBrand.heroImg
            })`,
          }}
        >
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                {currentBrand.title}
                {/*<span className="watermark">{currentBrand.title}</span>*/}
              </h1>
            </div>
          </div>
        </div>
        <Breadcrumbs />
        {/* <!-- Breadcrumbs End --> */}

        <div className="rs-team style7 blue-bg height-ctrl md-pb-30 md-pt-30">
          {/* <!-- Overlay Shop Start --> */}
          <div
            className="container container-fixwidth"
            style={{ display: isOverlayVisible ? "block" : "none" }}
          >
            <div className="collection-overlay gray-bg4">
              <p className="collection-overlay-text">
                {count} {t("brands.collectionProducts")}
              </p>
              {/* <!-- Shop online --> */}
              <div class="btn-online">
                <Link to="#" className="buy-online-button">
                  <span className="btn-txt">{t("brands.allProducts")}</span>
                  <i className="fa fa-cart-plus"></i>
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- Overlay Shop End --> */}
        </div>

        {/* <!-- Products Section Start --> */}
        {isLoading && <div className="loading-text blue-bg">Loading...</div>}
        {!isLoading && products?.length > 0 ? (
          <div className="rs-team style7 blue-bg height-ctrl md-pb-20 md-pt-20 product-load">
            <div className="container">
              <div className="row">
                {/* <!-- Filter Section --> */}
                <div className="col-md-2 col-12 filter-section">
                  {/* <!-- Add filter here --> */}
                  <div class="filter-section">
                    <span class="sort-label">{t("products.sortBy")}</span>
                    <ul class="sort-options">
                      <li>
                        <button
                          class={`filter-btn ${
                            searchParams.get("sort") === "popular" && "active"
                          }`}
                          onClick={() => sortBy("popular")}
                        >
                          {t("products.options.popular")}
                        </button>
                      </li>
                      <li>
                        <button
                          class={`filter-btn ${
                            searchParams.get("sort") === "latest" && "active"
                          }`}
                          onClick={() => sortBy("latest")}
                        >
                          {t("products.options.latest_arrivals")}
                        </button>
                      </li>
                      <li>
                        <button
                          class={`filter-btn ${
                            searchParams.get("sort") === "price_low" && "active"
                          }`}
                          onClick={() => sortBy("price_low")}
                        >
                          {t("products.options.price_low_high")}
                        </button>
                      </li>
                      <li>
                        <button
                          class={`filter-btn ${
                            searchParams.get("sort") === "price_high" &&
                            "active"
                          }`}
                          onClick={() => sortBy("price_high")}
                        >
                          {t("products.options.price_high_low")}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-10 col-12 products-section">
                  <div className="sec-title mb-40 mt-20 md-pb-20 md-pt-20">
                    <h2 className="title pb-22">{t("products.sub-title")}</h2>
                    <div className="heading-border-line left-style"></div>
                  </div>
                  <ul className="product-wrapper">
                    {products.map((product, i) => (
                      <li className="col-md-4 col-6 product-item" key={i}>
                        <ProductCard product={product} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="flex-row justify-content-center blue-bg gap-4" style={{display: "flex"}}>
          <button onClick={handleFirstPage}>First</button>
          <button onClick={handlePreviousPage} disabled={offset === 0}>
            Previous
          </button>
          <span>
            Page {Math.floor(offset / limit) + 1} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={offset + limit >= count}>
            Next
          </button>
          <button onClick={handleLastPage}>Last</button>
        </div>
        {/* <!-- Products Section End --> */}

        {/* <!-- Newsletter section start --> */}
        <div className="rs-newsletter blue-bg style1 pt-90 md-pt-60 mb--65">
          <Newsletter />
        </div>

        {/* <!-- Newsletter section end --> */}
      </div>
      {/* // <!-- Main content End --> */}
    </>
  );
}
