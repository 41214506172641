import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function LatestNewsCard({ news }) {
  const { t, i18n } = useTranslation();

  const dateObj = new Date(news.date);

  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("en-US", { month: "short" });

  const path =
    i18n?.language === "en"
      ? `/en/news/${news.path}`
      : news?.sr_path
      ? `/sr/vesti/${news.sr_path}`
      : `/sr/vesti/${news.path}`;

  return (
    <div className="blog-item">
      <div className="image-wrap">
        <Link to={path}>
          <img src={news.img} alt={news.url} />
        </Link>
        <div className="blog-date">
          <span className="day">{day}</span>
          <span className="month">{month}</span>
        </div>
      </div>
      <div className="blog-content">
        <ul className="blog-meta">
          <li className="admin">
            <i className="fa fa-user-o"></i>
            Lav Šćelović-Pokupec
          </li>
          <li className="cat-list">
            <Link to={path}>
              {" "}
              <i className="fa fa-folder"></i>
              {t("home.news.news")}
            </Link>
          </li>
        </ul>
        <h3 className="blog-title">
          <Link to={path}>{t(news.title)}</Link>
        </h3>
        <div className="blog-button">
          <Link to={path}>
            <span className="btn-txt">{t("home.read")}</span>
            <i className="fa fa fa-chevron-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}
