import React from 'react';

function HomePopularCard({ image, href, alt, badgeText, iconClass, cardClass }) {
  return (
    <div className={`brand-card ${cardClass}`}>
      <div className="badge-popular position-absolute top-0 start-0 bg-warning text-dark py-1 px-3">
        {badgeText}
      </div>
      <a href={href} className="card-link">
        <div className="image-wrapper">
          <img src={image} alt={alt} className="product-image" />
          <div className="overlay">
            <i className={iconClass + " cart-icon"}></i>
          </div>
        </div>
      </a>
    </div>
  );
}

export default HomePopularCard;
