import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function BrandsCard({ brand }) {
  const { i18n } = useTranslation();

  const path =
    i18n?.language === "en"
      ? `/en/brands/${brand.handle}`
      : `/sr/brendovi/${brand.handle}`;
  return (
    <div className="col-lg-2 col-md-6 col-6 mb-20">
      <div className="logo-item">
        <div className="grid-figure">
          <div className="logo-img">
            <Link title={brand.title} to={path}>
              {brand.metadata?.logo && (
                <img src={brand.metadata.logo} alt={brand.title} title={brand.title} />
              )}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
