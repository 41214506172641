import React from "react";
import { useTranslation } from "react-i18next";
import newsData from "../data/news.json";
import { Link } from "react-router-dom";

export default function NewsWidget() {
  const { t, i18n } = useTranslation();
  const newsArray = Object.values(newsData);

  return (
    <div className="recent-posts mb-50">
      <div className="widget-title">
        <h3 className="title">{t("home.news.recent")}</h3>
      </div>
      {/* <div className="recent-post-widget no-border">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/celebrate-valentines-day-with-duro-presents`}>
            <img
              src={newsData["celebrate-valentines-day-with-duro-presents"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/celebrate-valentines-day-with-duro-presents`}>
            {t(newsData["celebrate-valentines-day-with-duro-presents"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["celebrate-valentines-day-with-duro-presents"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget no-border">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/celebrate-holidays-with-duro-gifts`}>
            <img
              src={newsData["celebrate-holidays-with-duro-gifts"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/celebrate-holidays-with-duro-gifts`}>
            {t(newsData["celebrate-holidays-with-duro-gifts"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["celebrate-holidays-with-duro-gifts"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget no-border">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/black-friday`}>
            <img
              src={newsData["black-friday"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/black-friday`}>
            {t(newsData["black-friday"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["black-friday"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget no-border">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/exciting-new-perfume-arrivals-november`}>
            <img
              src={newsData["exciting-new-perfume-arrivals-november"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/exciting-new-perfume-arrivals-november`}>
            {t(newsData["exciting-new-perfume-arrivals-november"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["exciting-new-perfume-arrivals-november"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget no-border">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/arrival-of-dr-vranjes-ambient-perfumes-in-serbia`}>
            <img
              src={newsData["arrival-of-dr-vranjes-ambient-perfumes-in-serbia"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/arrival-of-dr-vranjes-ambient-perfumes-in-serbia`}>
            {t(newsData["arrival-of-dr-vranjes-ambient-perfumes-in-serbia"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["arrival-of-dr-vranjes-ambient-perfumes-in-serbia"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget no-border">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/new-brand-serbia-parfums-dusita`}>
            <img
              src={newsData["new-brand-serbia-parfums-dusita"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/new-brand-serbia-parfums-dusita`}>
            {t(newsData["new-brand-serbia-parfums-dusita"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["new-brand-serbia-parfums-dusita"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget no-border">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/opening-hours-easter-holiday-2023`}>
            <img
              src={newsData["opening-hours-easter-holiday-2023"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/opening-hours-easter-holiday-2023`}>
            {t(newsData["opening-hours-easter-holiday-2023"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["opening-hours-easter-holiday-2023"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/milorad-batta-mihailovitch-exhibition-duro-niche-perfumery`}>
            <img
              src={
                newsData[
                  "milorad-batta-mihailovitch-exhibition-duro-niche-perfumery"
                ].img
              }
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/milorad-batta-mihailovitch-exhibition-duro-niche-perfumery`}>
            {" "}
            {t(newsData["milorad-batta-mihailovitch-exhibition-duro-niche-perfumery"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["milorad-batta-mihailovitch-exhibition-duro-niche-perfumery"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/grand-opening-event-duro-niche-perfumery`}>
            <img
              src={newsData["grand-opening-event-duro-niche-perfumery"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/grand-opening-event-duro-niche-perfumery`}>
            {t(newsData["grand-opening-event-duro-niche-perfumery"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["grand-opening-event-duro-niche-perfumery"].date}{" "}
          </span>
        </div>
      </div> */}
      {newsArray.map((news, i) => {
        const path =
          i18n?.language === "en"
            ? `/en/news/${news.path}`
            : news?.sr_path
            ? `/sr/vesti/${news.sr_path}`
            : `/sr/vesti/${news.path}`;
        return (
          <div className="recent-post-widget no-border" key={i}>
            <div className="post-img">
              <Link to={path}>
                <img src={news.img} alt="" />
              </Link>
            </div>
            <div className="post-desc">
              <Link to={path}>{t(news.title)}</Link>
              <span className="date-post">
                {" "}
                <i className="fa fa-calendar"></i> {news.date}{" "}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
