import React from 'react';

function HomeProductCard({ image, collection, title, description, price, badge, href }) {
  return (
    <li className="col-md-4 col-6 product-item">
      <div className="product-card popular">
        <a href={href} className="card-link"> {/* Use the href prop here */}
          {badge && (
            <div className="badge-popular position-absolute top-0 start-0 bg-warning text-dark py-1 px-3">
              {badge}
            </div>
          )}
          <div className="image-wrapper">
            <img src={image} alt={title} className="product-image" />
            <div className="overlay">
              <i className="fa fa fa-shopping-cart cart-icon"></i>
            </div>
          </div>
          <div className="info-section">
            <p className="collection-text">{collection}</p>
            <p className="product-title">{title}</p>
            <p className="product-collection">{description}</p>
            <p className="product-price">{price}</p>
          </div>
        </a>
      </div>
    </li>
  );
}

export default HomeProductCard;
